import BaseService, { APIResponse } from "./BaseService";
import {GiorniChiusura} from "@/services/GiorniChiusuraService";

export type ServiziResponse = {
  id: number,
  nome: string,
  descrizione: string,
  prezzo: any
}[];

export type ServizioRequest ={
  servizio: {
    id:number
  },
  prezzo: number
}

export default class ServiziService extends BaseService {
  static async ListaServizi(idStruttura: number, idSport:number): Promise<APIResponse<ServiziResponse>> {
      return await this.perform<ServiziResponse>({
        url:"operatore/servizi/"+ idStruttura,
        params: {"id_sport": idSport},
        method: "GET"
      })
  }

  static async ListaCompletaServizi(): Promise<APIResponse<ServiziResponse>> {
    return await this.perform<ServiziResponse>({
      url:"operatore/servizi",
      method: "GET"
    })
  }

  static async AggiungiServizio(servizio: ServizioRequest, idStruttura: number): Promise<APIResponse<ServiziResponse>>{
    return await this.perform<ServiziResponse>({
      body: servizio,
      url:"operatore/servizi/"+ idStruttura,
      method: "POST"
    })
  }

  static async EliminaServizio(idServizio: number, idStruttura: number): Promise<APIResponse<ServiziResponse> | "">{
    return await this.perform<ServiziResponse>({
      url: 'operatore/servizi/delete',
      method: 'PUT',
      params: { "id_servizio": idServizio, "id_struttura": idStruttura}
    })
}
}
