





































import { Component, Vue } from "vue-property-decorator";
import StruttureService, {StruttureResponse} from "@/services/StruttureService";
import {Filtro} from "@/Classes/FiltroClass";
import ServiziService, {ServizioRequest, ServiziResponse} from "@/services/ServiziService";

class Form {
  servizio: number
  prezzo: number

  public constructor() {
    this.servizio = 0
    this.prezzo = 0
  }
}

@Component
export default class AddService extends Vue{

  public form: Form = new Form();
  public datiStruttura: StruttureResponse = []
  public listaServizi: ServiziResponse = []
  public filtro = new Filtro();


  rules = {
    //struttura:[{required: true, message:'Seleziona una struttura'}],
    servizio:[{required: true, message:'Seleziona un servizio'}],
    prezzo:[{required: true, message:'Immettere un prezzo'}]
  }

  public async RiceviStrutture(){
    try{
      const response = await StruttureService.getStrutture();

      if(response.error) {
        this.$message.error(response.msg)
      } else{
        this.datiStruttura = response.data;
        this.filtro.id_struttura = this.datiStruttura[0].struttura.value
      }
    }
    catch (err:any)
    {

    }
  }

  public async RiceviServizi(){
    try{
      const response = await ServiziService.ListaCompletaServizi();
      if(response.error) {
        this.$message.error(response.msg)
      } else{
        this.listaServizi = response.data;
        this.form.servizio = this.listaServizi[0].id
      }
    }
    catch (err:any)
    {

    }
  }

  async add(){
    const loginForm = this.$refs.form as any;

    loginForm.validate(async (valid:boolean) => {
      if(valid) {
        const servizio: ServizioRequest = {
          servizio:{
            id: this.form.servizio
          },
          prezzo: this.form.prezzo*100
        }

        const response = await ServiziService.AggiungiServizio(servizio, this.filtro.id_struttura)
        if(response.error){
          this.$message.error(response.msg)
        }else {
          await this.$router.push("servizi")
        }
      }
    })
  }

  mounted(){
    this.RiceviStrutture();
    this.RiceviServizi();
  }

}
